import deleteIcon from "../../../assets/common/delete_bin.svg";

export default function CardForNotifications({ name, description, time, newNotification, isExpanded, setIsExpanded }) {
    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="border-2 p-2 rounded-md cursor-pointer">
            <div className={`flex justify-between`} onClick={handleExpand}>
                <div className="flex gap-6 h-fit pl-4">
                    <div className="flex flex-col">
                        <h3 className="flex gap-6 items-center font-medium">{name}{newNotification && <div className="bg-secondaryBlue text-white text-xs rounded-xl px-3"><p className="pb-[2px]">new</p></div>}</h3>
                        <p className="text-textGray">{description}</p>
                    </div>
                </div>
                <div className="flex px-4 justify-center items-center gap-4">
                    {!isExpanded && <p className={`${newNotification && "text-lightBlue"} font-medium text-sm`}>{time}</p>}
                    <button type="button" onClick={(e) => { e.stopPropagation() }}>
                        <img src={deleteIcon} alt="delete" className="pb-1" /></button>
                </div>
            </div>
            {isExpanded &&
                <div className="pl-16 pr-4 cursor-default">
                    <div className="h-[1px] mt-6 w-full bg-black"></div>
                    <div className="flex w-fit">
                        <div className="pt-8 pb-4 flex flex-col gap-4 w-1/2">
                            <div className="flex">
                                <div className="w-40 flex">Lead ID</div><div>: L0003</div>
                            </div>
                            <div className="flex">
                                <div className="w-40 flex">Phone Number</div><div>: 1234567890</div>
                            </div>
                            <div className="flex">
                                <div className="w-40 flex">Gender</div><div>: Male</div>
                            </div>
                            <div className="flex">
                                <div className="w-40 flex">Course</div><div>: Computer Science and Engineering</div>
                            </div>
                            <div className="flex">
                                <div className="w-40 flex">Enquiry Date</div><div>: 22/10/2023</div>
                            </div>
                            <div className="flex">
                                <div className="w-40 flex">Follow Up Date</div><div>: 28/10/2023</div>
                            </div>
                        </div>
                        <div className="pt-8 pb-4 flex flex-col gap-4 w-1/2 pl-8">
                            <p>Description :</p>
                            <p className="">Due to unforeseen personal circumstances, I am unable to attend college today. I apologize for any inconvenience and appreciate your understanding.</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
