import { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginRightSideLayout from "../../containers/layouts/LoginRightSideLayout";
import TextInput from "../../components/inputs/TextInput";
import ReusableButton from "../../components/buttons/ReusableButton";
import CheckBoxForModal from "../../components/inputs/CheckBoxForModal";
import LoginVerify from "../../services/api/loginApi";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  
  const navigate = useNavigate();

  const validate = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Please enter your id");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid id");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter password");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Please enter a valid password");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        const userData = await LoginVerify.signin(email, password);
        console.log("userData", userData);
        if (userData) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error handling sign in", error);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave();
  };

  return (
    <>
      <LoginRightSideLayout
        helloMessage="   Hello Again !"
        pageTitle="      Please enter your details"
      >
        <form onSubmit={handleSubmit}>
          <div className=" flex flex-col  mt-[54px]  mb-[10px]   gap-[6px] items-center justify-center">
            <TextInput
              label="Admin ID"
              type="text"
              placeholder="Enter your admin id"
              value={email}
              error={emailError}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex flex-col items-center justify-center gap-1 mb-1 mt-3">
              <TextInput
                label="Password"
                type="password"
                placeholder="Enter the password"
                value={password}
                error={passwordError}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="flex items-center justify-center w-full mb-2 ">
                <div className="flex w-[390px] justify-between items-center mt-4">
                  {/* <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-5 h-5 border-2 border-[#D0D5DD] rounded-full outline-none form-checkbox"
                    />
                    <span className="ml-2 text-[#344054] font-[500]">Remember me</span>
                  </label> */}
                  <div className="flex justify-center items-center">
                    <CheckBoxForModal
                      index={0}
                      title="Remember me"
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                    />
                    <span className="ml-2 pt-[2px] text-[#344054] font-[500]">
                      Remember me
                    </span>
                  </div>
                </div>
              </div>
              <div className=" mt-2"></div>
              <ReusableButton
                title="Sign in"
                height="40px"
                onClick={handleSave}
                type="button"
                textColor={"white"}
              />
            </div>
          </div>
        </form>
      </LoginRightSideLayout>
    </>
  );
}
