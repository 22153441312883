import Modal from "../../components/Modal";

export default function ModalType1({
  logo,
  title,
  description,
  button1,
  button2,
  isOpen,
  setIsOpen,
  onClickButton1,
  onClickButton2,
}) {
  return (
    <>
      {isOpen &&
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isCloseButton={false}
          logo={logo}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-2 mt-3 px-3">
              <div className="font-semibold text-lg">{title}</div>
              <p className="text-textGray font-medium">{description}</p>
            </div>
            <div className="flex gap-5 justify-center items-center mt-8 font-medium pb-5">
              <button
                className="px-12 py-2 border-2 border-inputBorderGray rounded-lg"
                onClick={onClickButton1}
              >
                {button1}
              </button>
              <button
                className="px-12 py-2 border-2 border-primaryBlue rounded-lg bg-primaryBlue text-white"
                onClick={onClickButton2}
              >
                {button2}
              </button>
            </div>
          </div>
        </Modal>
      }
    </>
  );
}
