export default function TopCard({ title, count, isCardActive, setIsCardActive }) {
    return (
        <button type="button" className={`flex flex-col border border-inputBorderGray h-[100px] ${isCardActive === title && "scale-105 shadow-md -mt-1"} bg-white w-full`}
            onClick={() => setIsCardActive(title)}
        >
            <div className={`w-full h-4 ${isCardActive === title ? "bg-primaryBlue" : "bg-textGray"}`} />
            <div className={`pl-10 pt-4 ${isCardActive === title ? "text-textBlack" : "text-textGray"}`}>
                <p className="font-semibold">{title}</p>
                <p className="text-primaryBlue font-medium w-fit">{count}</p>
            </div>
        </button>
    )
}
