export default function CheckBoxForModal({
    index,
    isChecked,
    setIsChecked,
}) {

    const checkBoxStyle = `
        .custom-checkbox {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            bottom: 5px;
        }
        
        .custom-checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 13px;
            height: 13px;
            border: 1px solid #D0D5DD;
            border-radius: 3px;
            background-color: #fff;
        }
        
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 3px;
            top: 0.5px;
            width: 5px;
            height: 9px;
            border: solid #1849A9;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            border-radius: 1px;
        }
        
        .custom-checkbox input:checked~.checkmark:after {
            display: block;
        }
    `

    const handleChecked = (index) => {
        setIsChecked((prevChecked) => {
            const updatedChecked = { ...prevChecked };
            updatedChecked[index] = !updatedChecked[index];
            return updatedChecked;
        });
    };

    return (
        <button className="flex justify-center items-center" onClick={() => handleChecked(index)} type="button">
            <style>{checkBoxStyle}</style>
            <label htmlFor="checkbox" className="flex cursor-pointer">
                <div className="custom-checkbox flex justify-center items-center">
                    <input
                        name="checkbox"
                        type="checkbox"
                        readOnly
                        className="hidden"
                        checked={isChecked[index]}
                    />
                    <span className="checkmark"></span>
                </div>
            </label>
            <p className="text-dropdownTextGray font-medium">&nbsp;</p>
        </button>
    )
}