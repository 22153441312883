import { useEffect, useState } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../components/NavBar";
import TopNavigator from "../../containers/layouts/TopNavigator";
export default function NavbarLayout() {
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();

  const navigate = useNavigate();
  const url = location.pathname;

  useEffect(() => {
    const accessToken = localStorage.getItem("accessTokenCms");

    if (!accessToken) {
      navigate("/login");
    } else {
      if (url === "/") {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 768;
    if (isMobileScreen) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className=" w-full">
        {/* {children} */}
        <TopNavigator>
          <Outlet />
        </TopNavigator>
      </div>
    </Navbar>
  );
}
