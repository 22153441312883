import axios from "axios";

const Enquiry = {
  allEnquiry: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}enquiry/findAllEnquiry`,
        {
          params,
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
};

export default Enquiry;
