import ReusableButton from "./ReusableButton";
import plus from "../../assets/common/plus.svg";

export default function AddButton({ buttonTitle, buttonwidth, onClick }) {
    return (
        <ReusableButton
            text={buttonTitle}
            width={buttonwidth}
            bgColor="#1849A9"
            height="40px"
            onClick={onClick}
            imgicon={plus}
            textColor="#ffffff"
            borderColor="#1849A9"
            pxPadding="5px"
        />
    )
}
