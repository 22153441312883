import x from "../../../assets/modal/x.svg";
import Notifications from "./Notifications";

export default function NotificationsModal({
    isOpen,
    setIsOpen,
}) {
    return (
        <>
            {isOpen &&
                <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full overflow-hidden bg-black/40">
                    <div className="bg-white rounded-lg px-4">
                        <div className="flex justify-end pt-3">
                            <button
                                onClick={() => setIsOpen(false)}
                                className="p-3 h-fit bg-grayLightbg rounded-full"
                            >
                                <img src={x} alt="x" className="h-3" />
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-col items-center w-[1000px] justify-center gap-1 -mt-1 pb-4">
                                <Notifications />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}