import TextValidation from '../TextValidation';

const TextInput = ({
  label,
  type,
  placeholder,
  value,
  error,
  onChange,
  width,
  ...other
}) => {
  if (!width) {
    width = "400"
  }
  return (
    <div className="flex flex-col gap-1">
      <div className={`w-[${width}px] h-fit flex justify-start`}>
        <label
          className="text-[#344054] text-[18px] font-[500]"
          htmlFor={label}
        >
          {label}
        </label>
      </div>
      <div>
        <input
          type={type}
          name={label}
          id={label}
          className={`border ${error ? "border-[#F04438]" : "border-inputBorderGray"
            } text-[18px]  text-textBlack placeholder-textGray outline-none rounded-lg pl-2 py-2 w-[${width}px] focus:border-[#1849A9]`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...other}
        />
        <TextValidation>
          {error}
        </TextValidation>
      </div>
    </div>
  );
};

export default TextInput;
