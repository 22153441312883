import { useState } from "react";
import ReusableButton from "../../components/buttons/ReusableButton";
import MultiSelectDropDown from "../../components/inputs/MultiSelectDropDown";
import SearchInput from "../../components/inputs/SearchInput";

export default function FilterSection(props) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const batchDropdownData = [
        {
            option: "option1",
            value: "value1"
        },
        {
            option: "option1",
            value: "value1"
        },
        {
            option: "option1",
            value: "value1"
        },
    ];

    const sendRemainder = () => {
        props.setIsWarningModalOpen(true);
    };

    return (
        <div className="grid grid-cols-3 grid-rows-2 relative w-full gap-x-6 gap-y-3 items-center justify-between shadow-lg mt-2 border-[1px] py-3 px-4 rounded-md max-w-[1600px]">
            {/* Search */}
            <SearchInput
                placeholder="Roll Number"
            />
            {/* Batch */}
            <MultiSelectDropDown
                label="Batch"
                placeholder="2020 - 2024"
                data={batchDropdownData}
                displayValue={"option"}
                returnValue={"value"}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
            {/* Department */}
            <MultiSelectDropDown
                label="Department"
                placeholder="Computer Science"
                data={batchDropdownData}
                displayValue={"option"}
                returnValue={"value"}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
            {/* Profile Status */}
            <MultiSelectDropDown
                label="Profile Status"
                placeholder="Completed"
                data={batchDropdownData}
                displayValue={"option"}
                returnValue={"value"}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
            />
            {/* for grid - do not remove */}
            <div></div>
            {/* send remainder button */}
            <div className="w-fit absolute right-3 bottom-5">
                <ReusableButton
                    title="Send Remainder"
                    width="150px"
                    onClick={() => sendRemainder()}
                />
            </div>
        </div>
    )
}
