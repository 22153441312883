import signup from "../../assets/login/signup.svg";

export default function LoginSideLayout() {
  return (
    <div className=" bg-[#1849A9] w-full h-screen flex flex-col justify-center items-center gap-20">
      <div className="flex items-center justify-center w-full h-fit">
        <img src={signup} alt="" />
      </div>
      <div className="flex items-center justify-center w-full h-fit ">
        <div className="flex flex-col  w-fit h-full">
          <div className=" text-[60px] leading-none text-white ">
            X-ZETRA
          </div>
          <div className="text-lg text-right text-white ">
            Powered by TechHive
          </div>
        </div>
      </div>
    </div>
  )
}
