import { useState } from "react";

export default function Table({ columns, rows, pagination = 10, hero = true }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [rowsPerPageOptions] = useState([
    { id: '7', displayValue: '7' },
    { id: '10', displayValue: '10' },
    { id: '15', displayValue: '15' },
    { id: '20', displayValue: '20' },
  ]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page with new row count
  };

  // Calculate the pagination details
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentItems = rows && rows.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(rows?.length / rowsPerPage);

  return (
    <>
      <style>
        {`
    .overflow-y-auto::-webkit-scrollbar {
      width: 9px;
    }
    .overflow-y-auto::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155);
      border-radius: 10px;
    }
    .overflow-y-auto::-webkit-scrollbar-track {
      background-color: rgba(155, 155, 155, 0.1);
    }
  `}
      </style>
      <div className={`flex flex-col  `}>
        <div className="flex flex-col shadow-xl   overflow-auto z-0 border h-auto border-solid rounded-t-lg rounded-b-lg  bg-tableHeaderGray shadow-grey-200 border-tableBorderGray ">
          <table className="relative w-full ">
            <thead className="w-full h-0 text-xs font-medium leading-4 tracking-normal text-textGray font-inter">
              <tr className="h-0">
                {columns?.map((column, index) => (
                  <th key={index}>{column.name}</th>
                ))}
              </tr>
            </thead>
          </table>
          <div className="flex flex-col   shadow-xl bg-tableHeaderGray shadow-grey-200 ">
            <table className="relative w-full h-full ">
              <tbody className="w-full bg-white ">
                {currentItems?.map((row, index) => (
                  <tr key={index}>
                    {columns?.map((column, index) => (
                      <td
                        key={index}
                        className=" text-textGray py-2 border-t-[1px]"
                      >
                        {column?.cell(row)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-end w-full px-8 py-4 bg-white border-t text-[#667085] rounded-b-lg shadow-xl shadow-grey-200 border-tableBorderGray ">
            <div className=" flex justify-center items-center gap-1 px-7">
              <span>Rows per page:</span>
              <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                {[7, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </select>
            </div>
            <div className=" flex justify-center items-center ">
              Page <span className=" px-2">{currentPage}</span> of
              <span className=" px-2">{totalPages}</span>
            </div>
            <div className=" flex items-center justify-center gap-10 pl-7 pr-6">
              <button
                disabled={currentPage <= 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                >
                  <path
                    d="M0.292893 7.29289C-0.097631 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538409 7.04738 0.538409 6.65686 0.928933L0.292893 7.29289ZM1.5 7L1 7L1 9L1.5 9L1.5 7Z"
                    fill="#667085"
                  />
                </svg>
              </button>

              <button
                disabled={currentPage >= totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                >
                  <path
                    d="M8.20711 8.70711C8.59763 8.31658 8.59763 7.68342 8.20711 7.29289L1.84315 0.928932C1.45262 0.538408 0.819457 0.538407 0.428933 0.928932C0.0384083 1.31946 0.0384083 1.95262 0.428933 2.34315L6.08579 8L0.428932 13.6569C0.0384073 14.0474 0.0384073 14.6805 0.428932 15.0711C0.819456 15.4616 1.45262 15.4616 1.84315 15.0711L8.20711 8.70711ZM7 9L7.5 9L7.5 7L7 7L7 9Z"
                    fill="#667085"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
