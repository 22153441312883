import axios from "axios";
import { toast } from "react-toastify"; // Assuming you are using react-toastify for notifications
// import { navigate } from "react-router-dom"; // Assuming you are using react-router-dom for navigation

const LoginVerify = {
  signin: async (email, password) => {
    // Add email and password parameters
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/signin`,
        { email, password } // Pass email and password as data
      );

      if (response.data.status) {
        const accessToken = response.data.accessToken;
        const email = response.data.data.email;
        const name = response.data.data.name;
        const userType = response.data.data.userType;
        const id = response.data.data.id;

        localStorage.setItem("accessTokenCms", accessToken);
        localStorage.setItem("email", email);
        localStorage.setItem("name", name);
        localStorage.setItem("userType", userType);
        localStorage.setItem("userId", id);
        alert(response.data.message);

        console.log("response.data", response);
        
        return response.data; // Return the response data
      } else {
        alert(response.data.message);
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during login", error);
      toast.error("An error occurred during login");
      return null; // Return an appropriate value or throw the error
    }
  },
};

export default LoginVerify;
