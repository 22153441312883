export default function SearchInput({ label, width = "56", placeholder = "Search", value, setValue }) {
    return (
        <div className={`flex flex-col w-${width}`}>
            <p className="font-medium text-dropdownTextGray w-fit">{label}</p>

            <div className={`flex items-center border border-inputBorderGray rounded-[6px] gap-3 w-[${width}] h-[42.6px] px-3 bg-white`}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <input
                    type="text"
                    className="w-full h-full px-1 border-none outline-none placeholder-gray-500 pb-[1px]"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
        </div>
    )
}
