import { useNavigate } from "react-router-dom";
import x from "../assets/modal/x.svg";

export default function Modal({
  isOpen,
  setIsOpen,
  isCloseButton,
  logo,
  children,
  navigateTo = null,
}) {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(navigateTo);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full overflow-hidden bg-black/40">
          <div className="bg-white h-auto w-[400px] rounded-lg px-4">
            <div className="flex justify-between pt-3">
              <div>{logo && <img src={logo} alt="logo" />}</div>
              {isCloseButton && (
                <button
                  onClick={() => setIsOpen(false)}
                  className="p-1 pt-2 h-fit"
                >
                  {navigateTo ? (
                    <img src={x} alt="x" onClick={handleNav} />
                  ) : (
                    <img src={x} alt="x" />
                  )}
                </button>
              )}
            </div>
            <div className="flex flex-col">{children}</div>
          </div>
        </div>
      )}
    </>
  );
}
