import { useEffect, useRef, useState } from "react";
import CheckBox from "../../../components/inputs/CheckBox";
import ReusableButton from "../../../components/buttons/ReusableButton";
import CardForNotifications from "./CardForNotifications";
import ModuleLayout from "../../../containers/layouts/ModuleLayout";

import StartAndEndDates from "../../../components/inputs/StartAndEndDates";
import SearchInput from "../../../components/inputs/SearchInput";
import FilterButton from "../../../components/buttons/FilterButton";
import FilterAccordion from "../../../components/FilterAccordion";

export default function Notifications() {
    // ***** START OF FILTER CONTENT ****** 

    // const [isNotifications, setIsNotifications] = useState(false);
    const [date, setDate] = useState({
        firstDate: "",
        secondDate: "",
    });

    const [isChecked, setIsChecked] = useState({});
    const [isOpenArray, setIsOpenArray] = useState(
        Array.from({ length: 4 }, () => false)
    );
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const reff = useRef();
    const id = document.getElementById("scrollable");
    const handleClickOutside = (e) => {
        if (reff.current && !reff.current.contains(e.target)) {
            setIsFilterOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        reff.current = id;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handler = (e) => {
        if (e.target.contains(id)) {
            setIsFilterOpen(false);
        }
    };

    document.addEventListener("click", handler);

    const CheckBoxNotification = () => {
        const content = [
            {
                title: <div className="flex gap-2 text-[#98A2B3] font-normal">Un Read</div>,
            },
            {
                title: <div className="flex gap-2 text-[#98A2B3] font-normal">Read</div>,
            },
        ];
        return (
            <div className="flex flex-col gap-3 px-2">
                {content.map((item, index) => (
                    <CheckBox
                        key={index}
                        index={index}
                        title={item.title}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                    />
                ))}
            </div>
        );
    };

    const SortDate = () => {
        return (
            <div className="px-2">
                <StartAndEndDates
                    date={date}
                    setDate={setDate}
                    firstDateLabel={"Start Date"}
                    secondDateLabel={"End Date"}
                />
            </div>
        );
    };

    const filterContent = [
        {
            title: "Notification status",
            body: CheckBoxNotification(),
        },

        {
            title: "Sort by date ",
            body: SortDate(),
        },
    ];

    const filterChildren = (
        <div className="fixed top-0 left-0 right-0 z-50 w-full h-full overflow-hidden bg-black/40">
            <div
                id="scrollable"
                className="absolute rounded-md bg-white border-2 w-80 max-h-[70vh] overflow-y-auto top-[225px] right-[29.5rem] z-10"
                ref={reff}
            >
                <FilterAccordion
                    data={filterContent}
                    isOpenArray={isOpenArray}
                    setIsOpenArray={setIsOpenArray}
                />
                {isOpenArray.some((element) => element === true) && (
                    <div
                        className={`flex w-full justify-between px-4 pb-3`}
                    >
                        <ReusableButton
                            title={"Clear Filter"}
                            width={100}
                            textColor={"black"}
                            bgColor={"white"}
                            borderColor={"#D0D5DD"}
                            pxPadding={"5px"}
                        />
                        <ReusableButton
                            title={"Apply Filter"}
                            width={100}
                            textColor={"white"}
                            bgColor={"#1849A9"}
                            pxPadding={"5px"}
                        />
                    </div>
                )}
            </div>
        </div>
    );
    // ***** END OF FILTER CONTENT ****** 

    const [expandedStates, setExpandedStates] = useState([false, false, false, false, false]);

    const handleCardExpand = (index) => {
        const newExpandedStates = [...expandedStates];
        newExpandedStates[index] = !newExpandedStates[index];
        setExpandedStates(newExpandedStates);
    };

    const contents = [
        {
            name: "Charles",
            description: "Follow up time ended",
            time: "10:39 AM Sep 23, 2023",
            newNotification: true,
        },
        {
            name: "Taylor",
            description: "Follow up time ended",
            time: "10:30 AM Sep 23, 2023",
            newNotification: true,
        },
        {
            name: "Selena",
            description: "Follow up time ended",
            time: "10:30 AM Sep 22, 2023",
            newNotification: false,
        },
        {
            name: "Selena",
            description: "Follow up time ended",
            time: "10:30 AM Sep 22, 2023",
            newNotification: false,
        },
        {
            name: "Selena",
            description: "Follow up time ended",
            time: "10:30 AM Sep 22, 2023",
            newNotification: false,
        },
    ];

    const [search, setSearch] = useState("");

    return (
        <ModuleLayout>
            <div className="px-4 py-8 rounded-lg">
                <div className="flex justify-between items-center">
                    <div className="flex flex-col gap-4 -mt-10 w-full">
                        <h1 className="text-3xl font-semibold">Notifications</h1>
                        <div className="flex justify-between w-full">
                            <SearchInput
                                width="80"
                                value={search}
                                setValue={setSearch}
                            />
                            <div className="relative">
                                <FilterButton onClick={() => setIsFilterOpen(true)} />
                                {isFilterOpen && (<div>{filterChildren} </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`pt-6 flex flex-col gap-1 mt-4 h-[calc(70vh-100px)] overflow-y-auto ${expandedStates.some((s) => s) && `pr-4`}`}>
                    {contents.map((content, index) => (
                        <CardForNotifications
                            key={index}
                            name={content.name}
                            description={content.description}
                            time={content.time}
                            newNotification={content.newNotification}
                            isExpanded={expandedStates[index]}
                            setIsExpanded={() => handleCardExpand(index)}
                        />
                    ))}
                </div>
            </div>
        </ModuleLayout>
    );
}
