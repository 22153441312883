import company_logo from "../../assets/login/company_logo.svg";

export default function LoginRightSideLayout({
  helloMessage,
  pageTitle,
  descriptionLine1,
  descriptionLine2,
  descriptionLine3,
  children,
}) {
  return (
    <>
      <div className=" bg-[#fff] w-full h-screen flex-col flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-full h-full">
            <img src={company_logo} alt="" />
          </div>
          <div className="  text-[40px] text-black font-[500] ">
            {helloMessage}
            <div className=" text-[#344054] text-[16px] text-center">{pageTitle}</div>
          </div>
        </div>
        <div className="flex-col gap-3 mt-2">
          <div className="  text-[#344054] text-[16px] text-center font-[500] ">
            {descriptionLine1}
          </div>
          <div className="  text-[#344054] text-[16px] text-center font-[500] ">
            {descriptionLine2}
          </div>
          <div className="  text-[#344054] text-[16px] text-center font-[500] ">
            {descriptionLine3}
          </div>
        </div>
        {children}
      </div>
    </>
  );
}
