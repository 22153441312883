import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import cms_logo from "../assets/navbar/cms_logo.svg";
import arrow_right from "../assets/common/arrow_right.svg";
import arrow_left from "../assets/common/arrow_left.svg";
import search from "../assets/common/search.svg";
import settings from "../assets/navbar/settings.svg";
import avatar from "../assets/navbar/profile_avatar.svg";
import logout from "../assets/navbar/sign_out.svg";

import home from "../assets/navbar/home.svg";
import enquiry from "../assets/navbar/enquiry.svg";
import admission from "../assets/navbar/admission.svg";
import students from "../assets/navbar/students.svg";
// import exam_result from "../assets/navbar/exam_result.svg";
// import time_table from "../assets/navbar/time_table.svg";

export default function NavBar({ isOpen, setIsOpen, children }) {
  const path = useLocation().pathname;

  const LinkActive = (link) => {
    const linkCase = link.toLowerCase();
    const pathCase = path.toLowerCase();
    return pathCase.includes(linkCase);
  };

  const scrollBarCSS = `
        #scrollableNav::-webkit-scrollbar {
            width: 4px;
        }
        #scrollableNav::-webkit-scrollbar-thumb {
            background: #2E90FA;
            border-radius: 6px;
        }
        #labelSearch {
            position: relative;
        }
        #labelSearch:before {
            content: "";
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            width: 16px;
            background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 16L12.375 12.375M14.3333 7.66667C14.3333 11.3486 11.3486 14.3333 7.66667 14.3333C3.98477 14.3333 1 11.3486 1 7.66667C1 3.98477 3.98477 1 7.66667 1C11.3486 1 14.3333 3.98477 14.3333 7.66667Z' stroke='gray' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") center / contain no-repeat;
        }
    `;

  const navContent = [
    {
      img: home,
      alt: "leads",
      title: "Leads",
      link: "/",
    },
    {
      img: enquiry,
      alt: "enquiry",
      title: "Enquiry",
      link: "/enquiry",
    },
    {
      img: admission,
      alt: "admission",
      title: "Admission",
      link: "/admission",
    },
    {
      img: students,
      alt: "students",
      title: "Students",
      link: "/students",
    },
    // {
    //   img: exam_result,
    //   alt: "exam_result",
    //   title: "Exam Result",
    //   link: "/exam-result",
    // },
    // {
    //   img: time_table,
    //   alt: "time_table",
    //   title: "Time Table",
    //   link: "/time-table",
    // }
  ];

  const user = {
    avatar: avatar,
    name: "Olivia Rhye",
    email: "olivia@untitledui.com",
  };

  // useEffect(() => {
  //   path !== "/" ? setIsOpen(true) : setIsOpen(false);
  //   // eslint-disable-next-line
  // }, [path]);

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  return (
    <div className="flex h-screen">
      <style>{scrollBarCSS}</style>
      <nav
        className={`flex flex-col justify-between h-full bg-primaryBlue text-white ${
          !isOpen && "items-center"
        }`}
      >
        <div className="w-full">
          <div
            className={`h-[8vh] flex items-center justify-center gap-4 py-8 bg-secondaryBlue ${
              !isOpen && "justify-center"
            }`}
          >
            <Link to="/">
              <img src={cms_logo} alt="Logo" className="h-10" />
            </Link>
            {isOpen && <p className="text-3xl font-medium">CMS</p>}
          </div>
          <div
            id="scrollableNav"
            className={`h-[70vh] flex flex-col gap-[10px] px-2 pt-2 overflow-y-auto ${
              !isOpen && "items-center"
            }`}
          >
            <div
              className={`flex flex-col gap-3 w-full ${
                isOpen ? "items-end" : "items-center"
              }`}
            >
              <button
                onClick={handleSetIsOpen}
                className={`flex items-center w-fit hover:bg-lightBlue px-2 py-2 rounded-md`}
              >
                {isOpen ? (
                  <img src={arrow_left} alt="Expand" className="h-4" />
                ) : (
                  <img src={arrow_right} alt="Expand" className="h-4" />
                )}
              </button>
              <button
                className="flex justify-center w-full"
                onClick={() => !isOpen && setIsOpen(true)}
              >
                {isOpen ? (
                  <label
                    id="labelSearch"
                    htmlFor="search"
                    className="text-black"
                  >
                    <input
                      name="search"
                      type="text"
                      className="py-1 pl-10 rounded-md focus:outline-none"
                      placeholder={`Search`}
                    />
                  </label>
                ) : (
                  <img
                    src={search}
                    alt="Search"
                    className="px-2 py-2 rounded-md hover:bg-lightBlue"
                  />
                )}
              </button>
            </div>
            {navContent.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className={`flex items-center gap-3 px-2 py-2 rounded-md hover:bg-lightBlue ${
                  LinkActive(item.link) && "bg-lightBlue"
                }`}
              >
                <img src={item.img} alt={item.alt} className="h-5" />
                {isOpen && <p>{item.title}</p>}
              </Link>
            ))}
          </div>
        </div>
        <div
          className={`h-[22vh] flex flex-col justify-end pb-4 px-2 ${
            !isOpen ? "items-center gap-3" : "gap-2"
          }`}
        >
          <div className="flex flex-col gap-2">
            <Link
              to="/profile"
              className={`flex items-center gap-4 px-2 py-2 rounded-md hover:bg-lightBlue ${
                path === "/profile" && "bg-lightBlue"
              }`}
            >
              <img
                src={settings}
                alt="Settings"
                className="h-5 rounded-md hover:bg-lightBlue"
              />
              {isOpen && <p>Settings</p>}
            </Link>

            <div className="bg-secondaryBlue h-[1.5px] w-full" />
          </div>
          <div className="flex justify-center gap-2">
            <Link to="">
              <img src={user.avatar} alt="Avatar" className={`h-10`} />
            </Link>
            {isOpen && (
              <div className="flex gap-4">
                <div className="flex flex-col justify-center text-sm">
                  <p>{user.name}</p>
                  <p>{user.email}</p>
                </div>

                <button
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                  className="h-6"
                >
                  <img src={logout} alt="Logout" className="h-[18px]" />
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
      {children}
    </div>
  );
}
