import { useEffect, useRef, useState } from "react";
import Table from "../../components/Table";
import DateUtils from "../../services/utility/DateUtils";
export default function HomeTable({ rowData, setSelectedRowsValue }) {
  const [selectedRows, setSelectedRows] = useState(new Set());

  useEffect(() => {
    if (selectedRows.size > 0) {
      setSelectedRowsValue(true);
    } else {
      setSelectedRowsValue(false);
    }
  }, [selectedRows]);
  // Function to handle checkbox toggle
  const handleCheckboxToggle = (index) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(index)) {
        newSelectedRows.delete(index);
      } else {
        newSelectedRows.add(index);
      }
      return newSelectedRows;
    });
  };

  // Function to handle master checkbox toggle
  const handleMasterCheckboxToggle = () => {
    if (selectedRows?.size === rowData?.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(rowData?.map((row) => row?.index)));
    }
  };

  const columns = [
    {
      id: "checkbox",
      name: (
        <div className="flex items-center text-left  pl-[11px]  h-[60px]">
          <input
            type="checkbox"
            checked={selectedRows?.size === rowData?.length}
            onChange={handleMasterCheckboxToggle}
          />
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center pl-[7px]  justify-center  h-[60px]">
          <input
            type="checkbox"
            checked={selectedRows.has(row.index)}
            onChange={() => handleCheckboxToggle(row.index)}
          />
        </div>
      ),
    },
    {
      id: "name",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Name
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.studentName}
        </div>
      ),
    },
    {
      id: "leadNumber",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Application Number
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.applicationNumber}
        </div>
      ),
    },
    {
      id: "enquiryDate",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Enquiry Date
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {DateUtils.convertDate(row.createdAt)}
        </div>
      ),
    },
    {
      id: "mobileNumber",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Mobile Number
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.mobileNumber}
        </div>
      ),
    },
    {
      id: "gender",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Gender
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.gender}
        </div>
      ),
    },
    {
      id: "course",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Course
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.course.courseName}
        </div>
      ),
    },
    {
      id: "branch",
      name: (
        <div className="flex items-center text-left w-40  pl-[11px]     h-[60px]">
          Branch
        </div>
      ),
      cell: (row) => (
        <div className="flex items-center text-left w-40 pl-4  h-[60px]">
          {row.branch.branchName}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full pl-3 pr-2 pb-5">
      <Table columns={columns} rows={rowData} />
    </div>
  );
}
