import { Route, Routes } from "react-router-dom";

import LoginPageLayout from "./containers/layouts/LoginPageLayout";
import NavbarLayout from "./containers/layouts/NavBarLayout";

// pages
import Admission from "./pages/admission/Index";
import Students from "./pages/students/Index";
// import ExamResult from "./pages/exam_result/Index";
// import TimeTable from "./pages/time_table/Index";
// import CommentsModal from "./pages/home/comments/CommentsModal";
import LoginPage from "./pages/login/Index";
import Home from "../src/pages/Home/Index";
import Enquiry from "./pages/enquiry/Index";

export default function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<NavbarLayout />} >
          <Route path="" element={<Home />} />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="profile" element={<></>} />
          <Route path="admission" element={<Admission />} />
          <Route path="students" element={<Students />} />
          {/* <Route path="modal" element={<CommentsModal />} /> */}
          {/* <Route path="/exam-result" element={<ExamResult />} />
            <Route path="/time-table" element={<TimeTable />} /> */}
        </Route>
        <Route path="/" element={<LoginPageLayout />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </>
  );
};
