import { useEffect, useState, useRef } from "react";
import Mandate from "../Mandate";

export default function DropDown({
  label,
  data,
  displayValue,
  defaultValue,
  width,
  placeholder,
  selectedOption,
  setSelectedOption,
  mandate,
  disabled,
  readOnly,
  error,
  selectAll
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (item) => {
    if (item[displayValue] === "--- Select All ---") {
      setSelectedOption("");
      setIsDropdownOpen(false);
      return;
    }
    setSelectedOption(item);
    setIsDropdownOpen(false);
  };

  const inputStyle = {
    width: width || "100%",
  };

  const modifiedData = selectAll
    ? [{ [displayValue]: "--- Select All ---" }, ...data]
    : data;
  return (
    <div
      className={`flex flex-col min-w-[200px]  max-w-[250px]`}
      style={{
        width: inputStyle.width
      }}
    >
      <div className="text-[#344054] text-[15px] pl-1 font-medium">
        {label}<Mandate mandate={mandate} />
      </div>
      <div ref={dropdownRef} className={`flex-auto relative w-56]`}>
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          disabled={disabled}
          className={`text-center ${readOnly && "cursor-default"} flex justify-around ${!selectedOption && error ? "border-[#F04438]" : "border-inputBorderGray"} w-full z-40 px-3 pl-2 h-[40px] items-center text-[#4e4e4e] border appearance-none ${disabled ? "bg-[#eee]" : "bg-white"} rounded-[6px] focus:outline-none focus:bg-white`}

          type="button"
          onClick={toggleDropdown}
        >
          <div
            className={`flex pl-[1px] w-full text-sm ${selectedOption && selectedOption[displayValue] || defaultValue ? "text-[#101828]" : "text-placeholderTextGray"
              }`}
          >
            {(selectedOption === "" && selectAll ? "--- Select All ---" : selectedOption && selectedOption[displayValue]) || defaultValue || placeholder}
          </div>
          <svg
            className={`w-2.5 ${isDropdownOpen && !disabled && !readOnly && "transform rotate-180"}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="#6F7990"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {isDropdownOpen && (data?.length > 0 && !disabled && !readOnly ? (
          <div
            id="dropdown"
            className={`absolute z-40 drop-shadow-xl mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none`}
            style={{
              width: inputStyle.width
            }}
          >
            <ul className="py-2 text-sm text-gray-700">
              {modifiedData.map((item, index) => (
                <li key={index}>
                  <button
                    href="#"
                    className={`block px-4 py-2 w-full text-left hover:bg-gray-100`}
                    onClick={() => handleOptionClick(item)}
                  >
                    <p className="text-dropdownTextGray">
                      {item[displayValue]}
                    </p>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className=" absolute  text-[#F04438] font-normal text-[13px] text-left">
            Select previous dropdown
          </div>
        ))}
        {!selectedOption && error && (
          <div className=" absolute  text-[#F04438] font-normal text-[13px] text-left">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}