export default function CheckBox({
  index,
  title,
  isChecked,
  setIsChecked,
  isMultiSelect,
  selectedIndex,
}) {
  const checkBoxStyle = `
        .custom-checkbox {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            bottom: 5px;
        }
        
        .custom-checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 13px;
            height: 13px;
            border: 1px solid #1849A9;
            border-radius: 3px;
            background-color: #fff;
        }
        
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 3px;
            top: 0.5px;
            width: 5px;
            height: 9px;
            border: solid #1849A9;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            border-radius: 1px;
        }
        
        .custom-checkbox input:checked~.checkmark:after {
            display: block;
        }
    `;

  const handleChecked = () => {
    if (isMultiSelect) {
      setIsChecked((prevChecked) => {
        const updatedChecked = { ...prevChecked };
        updatedChecked[index] = !updatedChecked[index];
        return updatedChecked;
      });
    } else {
      setIsChecked(() => {
        const updatedChecked = {};

        if (index === selectedIndex) {
          updatedChecked[index] = !isChecked;
        } else {
          updatedChecked[index] = true;
        }
        return updatedChecked;
      });
    }
  };

  return (
    <button
      className="flex gap-4 items-center"
      onClick={handleChecked}
      type="button"
    >
      <style>{checkBoxStyle}</style>
      <label
        htmlFor={`checkbox-${index}`}
        className="flex gap-4 pointer-events-none"
      >
        <div className="flex justify-center items-center custom-checkbox">
          <input
            id={`checkbox-${index}`}
            name={`checkbox-${index}`}
            type="checkbox"
            readOnly
            className="hidden"
            checked={isChecked[index] || false}
          />
          <span className="checkmark"></span>
        </div>
      </label>
      <div className=" font-medium pt-1">{title}</div>
    </button>
  );
}
