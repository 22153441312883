import ModuleLayout from "../../containers/layouts/ModuleLayout";

export default function Enquiry() {

  return (
    <ModuleLayout
      title="Enquiry"
    >
      <div className="flex flex-col gap-8 w-full h-[calc(100%-80px)]">
        {/*  */}
      </div>
    </ModuleLayout>
  )
};
