import React, { useState } from "react";
import Seacharrow from "../assets/common/tooltip_arrow.svg";

export default function ToolTipForSearch({ text, children, width }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000)
  };

  return (
    <div className="relative inline-block">
      <div className="group" onClick={toggleTooltip}>
        {children}
        {showTooltip && text && (
          <div
            className={`flex justify-center items-center absolute -mt-24 w-[${width}] p-3 border border-gray-300 rounded-md shadow-lg text-sm font-inter font-semibold leading-5 text-gray-700 transition-all duration-[3000] z-40 bg-white `}
          >
            {text}
            <img
              src={Seacharrow}
              alt="Search"
              className="absolute bottom-[-8px]  "
            />
          </div>
        )}
      </div>
    </div>
  );
};