export default function FilterAccordion({
  data,
  width,
  isOpenArray,
  setIsOpenArray
}) {
  const toggleAccordion = (index) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = !newIsOpenArray[index];
    setIsOpenArray(newIsOpenArray);
  };

  return (
    <div className={`flex flex-col w-[${width}px] rounded-md`}>
      {data.map((item, index) => (
        <div key={index} className="w-full">
          <button
            className={`flex items-center justify-between w-full py-2 px-4 ${isOpenArray[index] && "bg-blue-100/50"
              } cursor-pointer`}
            onClick={() => toggleAccordion(index)}
          >
            <p className={`${isOpenArray[index] ? "text-textBlack" : "text-[#667085]"} font-medium`}>{item.title}</p>
            <span
              className={`${isOpenArray[index] && "rotate-180 transition-all"}`}
            >
              <svg
                width="15"
                height="9"
                viewBox="0 0 15 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.79289 8.20711C7.18342 8.59763 7.81658 8.59763 8.20711 8.20711L14.5711 1.84315C14.9616 1.45262 14.9616 0.819457 14.5711 0.428932C14.1805 0.0384081 13.5474 0.0384081 13.1569 0.428932L7.5 6.08579L1.84315 0.428932C1.45262 0.0384075 0.819457 0.0384075 0.428932 0.428932C0.0384081 0.819456 0.038408 1.45262 0.428932 1.84315L6.79289 8.20711ZM6.5 6.5L6.5 7.5L8.5 7.5L8.5 6.5L6.5 6.5Z"
                  fill="#1849A9"
                />
              </svg>
            </span>
          </button>
          <div
            className={`transition-all ${isOpenArray[index] ? "block" : "hidden"
              } px-2 py-2`}
          >
            {item.body}
          </div>
        </div>
      ))}
    </div>
  );
}
