import ReusableButton from "./ReusableButton";
import filterIcon from "../../assets/common/filter.svg";

export default function FilterButton({ onClick, ...others }) {
  return (
    <ReusableButton
      title="Filters"
      width="100px"
      bgColor="#ffffff"
      height="40px"
      onClick={onClick}
      imgicon={filterIcon}
      textColor="#344054"
      borderColor="#D0D5DD"
      pxPadding="10px"
    />
  )
}
