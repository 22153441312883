import { useEffect, useState } from "react";
import ModuleLayout from "../../containers/layouts/ModuleLayout";
import bell from "../../assets/common/bell.svg";
import HomeTable from "../../containers/tables/HomeTable";
import TopCard from "./TopCard";
import SearchInput from "../../components/inputs/SearchInput";
import DropDown from "../../components/inputs/DropDown";
import DateInput from "../../components/inputs/DateInput";
import ReusableButton from "../../components/buttons/ReusableButton";
import HomeTable2 from "../../containers/tables/HomeTable2";
import HomeTable4 from "../../containers/tables/HomeTable4";
import NotificationsModal from "./notifications/NotificationsModal";

import Common from "../../services/api/commonApi";
import Enquiry from "../../services/api/enquiryApi";

export default function Home() {
  const [enquiryData, setEnquiryData] = useState([]);

  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [action, setAction] = useState("");
  const [selectedRows, setSelectedRowsValue] = useState(false);
  const [isCardActive, setIsCardActive] = useState("Leads");
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const [selectedCourseCategory, setSelectedCourseCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedGender, setSelectedGender] = useState("");

  const [courseCategoryList, setCourseCategoryList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const genderList = [
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
    { id: "Other", name: "Other" },
  ];

  useEffect(() => {
    const fetchAllEnquiryData = async () => {
      try {
        const params = {
          searchQuery: search,
          gender: selectedGender.id,
          fromDate: startDate,
          toDate: endDate,
          courseCategoryID: selectedCourseCategory.id,
          courseID: selectedCourse.id,
          branchID: selectedBranch.id,
        };

        console.log("params:", params);

        const allEnquiryData = await Enquiry.allEnquiry(params);
        setEnquiryData(allEnquiryData);
      } catch (error) {
        console.error("Error fetching enquiry data", error);
      }
    };
    fetchAllEnquiryData();
  }, [
    search,
    selectedGender,
    startDate,
    endDate,
    selectedCourseCategory,
    selectedCourse,
    selectedBranch,
  ]);

  useEffect(() => {
    const fetchCourseCategoryData = async () => {
      try {
        const allCourseCategory = await Common.courseCategory();
        setCourseCategoryList(allCourseCategory);
      } catch (error) {
        console.error("Error fetching course-category data", error);
      }
    };
    fetchCourseCategoryData();
  }, []);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const allCourse = await Common.course(selectedCourseCategory.id);
        setCourseList(allCourse);
      } catch (error) {
        console.error("Error fetching course data", error);
      }
    };
    fetchCourseData();
    // setSelectedCourse("");
  }, [selectedCourseCategory]);

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const allBranch = await Common.branch(selectedCourse.id);
        setBranchList(allBranch);
      } catch (error) {
        console.error("Error fetching branch data", error);
      }
    };
    // setSelectedBranch("");
    fetchBranchData();
  }, [selectedCourse]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Update screen size on window resize
  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [widthForFilters, setWidthForFilters] = useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (windowDimensions.width <= 1645) {
      setWidthForFilters(true);
    } else setWidthForFilters(false);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions.width]);

  const topCardsData = [
    {
      title: "Leads",
      count: enquiryData.enquiry?.length,
    },
    {
      title: "Follow up",
      count: 0,
    },
    {
      title: "Visited",
      count: 0,
    },
    {
      title: "Applied",
      count: 0,
    },
    {
      title: "Enrolled",
      count: 0,
    },
  ];

  // const dropDownData = [
  //   {
  //     title: "Leads",
  //     count: 40
  //   },
  //   {
  //     title: "Follow up",
  //     count: 40
  //   },
  //   {
  //     title: "Visited",
  //     count: 40
  //   },
  //   {
  //     title: "Applied",
  //     count: 40
  //   },
  //   {
  //     title: "Enrolled",
  //     count: 40
  //   }
  // ];

  const handleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  // const handleApply = () => {
  //   console.log("search:", search);
  //   console.log("gender:", selectedGender);
  //   console.log("courseCategory:", selectedCourseCategory);
  //   console.log("course:", selectedCourse);
  //   console.log("branch:", selectedBranch);
  //   console.log("start date:", startDate);
  //   console.log("end date:", endDate);
  //   console.log("action:", action);
  // };

  return (
    <>
      <ModuleLayout>
        <style>
          {`
    .overflow-y-auto::-webkit-scrollbar {
      width: 9px;
    }
    .overflow-y-auto::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155);
      border-radius: 10px;
    }
    .overflow-y-auto::-webkit-scrollbar-track {
      background-color: rgba(155, 155, 155, 0.1);
    }
  `}
        </style>
        <button
          className="absolute top-0 right-2 p-4"
          onClick={handleNotifications}
        >
          <img src={bell} alt="" />
        </button>
        <div className="flex flex-col w-full h-[calc(100%-40px)] overflow-y-auto mt-8 border rounded-md pr-2">
          <div className="flex pr-0 border-b">
            <button
              type="button"
              className="w-[70px] flex justify-center items-center"
            >
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H2V7H1V9Z"
                  fill="#667085"
                />
              </svg>
            </button>
            <div className="my-3 flex w-full">
              {topCardsData.map((data, index) => {
                return (
                  <TopCard
                    key={index}
                    title={data.title}
                    count={data.count}
                    isCardActive={isCardActive}
                    setIsCardActive={setIsCardActive}
                  />
                );
              })}
            </div>
          </div>
          <div className="relative">
            <div
              className={`flex flex-wrap gap-y-4 ${
                widthForFilters ? "gap-4" : "justify-between"
              }  pl-4 mt-8 mb-4 items-start`}
            >
              <SearchInput
                placeholder="Search"
                value={search}
                setValue={setSearch}
              />
              <DropDown
                placeholder={"Select gender"}
                data={genderList}
                displayValue={"name"}
                selectedOption={selectedGender}
                setSelectedOption={setSelectedGender}
              />
              <DropDown
                placeholder={"Select category course"}
                data={courseCategoryList}
                displayValue={"courseCategoryName"}
                selectedOption={selectedCourseCategory}
                setSelectedOption={setSelectedCourseCategory}
              />
              <DropDown
                placeholder={"Select course"}
                data={courseList}
                displayValue={"courseName"}
                selectedOption={selectedCourse}
                setSelectedOption={setSelectedCourse}
              />
              <DropDown
                placeholder={"Select branch"}
                data={branchList}
                displayValue={"branchName"}
                selectedOption={selectedBranch}
                setSelectedOption={setSelectedBranch}
              />
              <DateInput
                placeholder={"Start date"}
                date={startDate}
                setDate={setStartDate}
              />
              <DateInput
                placeholder={"End date"}
                date={endDate}
                setDate={setEndDate}
              />
              {/* {
                selectedRows && (
                  <div className={`${screenSize.width >= 1639 ? "w-full" : ""}  flex justify-end items-center pr-1`}>
                    <div className="flex gap-3">
                      <DropDown
                        width="40"
                        placeholder={"Action"}
                        data={dropDownData}
                        displayValue={"title"}
                        selectedOption={action}
                        setSelectedOption={setAction}
                      />
                      <ReusableButton title={"Apply"} width="100px"
                        onClick={() => handleApply()}
                      />
                    </div>
                  </div>
                )
              } */}
            </div>
            {/* <div className="flex gap-3 absolute top-20 right-0"> */}

            {/*           </div> */}
            {isCardActive === "Leads" ? (
              <HomeTable
                rowData={enquiryData.enquiry}
                setSelectedRowsValue={setSelectedRowsValue}
              />
            ) : null}
            {/* ) : isCardActive === "Follow up" ? (
              <HomeTable2 setSelectedRowsValue={setSelectedRowsValue} />
            ) : isCardActive === "Visited" ? (
              <HomeTable setSelectedRowsValue={setSelectedRowsValue} />
            ) : isCardActive === "Applied" ? (
              <HomeTable4 setSelectedRowsValue={setSelectedRowsValue} />
            ) : isCardActive === "Enrolled" ? (
              <HomeTable setSelectedRowsValue={setSelectedRowsValue} />
            ) : null} */}
          </div>
        </div>
      </ModuleLayout>
      <NotificationsModal
        isOpen={isNotificationsOpen}
        setIsOpen={setIsNotificationsOpen}
      />
    </>
  );
}
