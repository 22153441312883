import search from "../../assets/common/search.svg";
import ToolTipForSearch from "../ToolTipForSearch";

export default function SearchWithToolTip({
  TooltipText,
  TooltipWidth,
  SearchWidth,
}) {

  return (
    <div className="flex items-center w-fit">
      <ToolTipForSearch text={TooltipText} width={TooltipWidth}>
        <div
          className={`flex items-center border-[1px] rounded-lg  w-[${SearchWidth}] h-[44px] p-1 bg-white`}
        >
          <img src={search} alt="Search Icon" className="px-1" />

          <input
            type="text"
            className="w-full h-full  px-1 border-none outline-none placeholder-grey-500 "
            placeholder="Search"
          />
        </div>
      </ToolTipForSearch>
    </div>
  );
}
