import { useState } from "react";
import ModuleLayout from "../../containers/layouts/ModuleLayout";
import ModalType1 from "../../containers/modals/ModalType1";
import warning from "../../assets/modal/warning.svg"
import success from "../../assets/modal/success_animated.gif"
import ModalType2 from "../../containers/modals/ModalType2";
import FilterSection from "./FilterSection";
import Table from "./Table";

export default function Students() {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleSuccessModal = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
    setIsSuccessModalOpen(!isSuccessModalOpen);
  };

  return (
    <>
      <ModuleLayout
      >
        <FilterSection
          setIsWarningModalOpen={setIsWarningModalOpen}
        />
        <Table />
      </ModuleLayout>

      {/* modals */}
      <ModalType1
        logo={warning}
        isOpen={isWarningModalOpen}
        setIsOpen={setIsWarningModalOpen}
        title={"Send Reminder ?"}
        description={"Do you want to send reminder to the selected students?"}
        button1={"Yes"}
        button2={"No"}
        onClickButton1={() => handleSuccessModal()}
        onClickButton2={() => setIsWarningModalOpen(!isWarningModalOpen)}
      />
      <ModalType2
        animation={success}
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
        title={"Reminder *Sended* Successfully"}
        button1={"Yes"}
        button2={"No"}
      />
    </>
  )
};
