import { useRef, useState } from "react";

export default function DateInput({
    date,
    setDate,
    placeholder,
    width = "56",
    label,
    mandatory,
    disabled,
    readOnly,
    error,
}) {
    const dateRef = useRef();
    // eslint-disable-next-line
    const [active, setActive] = useState(false);
    const handleClick = (e) => {
        if (e.target.nodeName === "INPUT") {
            return;
        }
        dateRef.current.showPicker();
        setActive(true);
    };

    const handleChange = (e) => {
        setDate(e.target.value);
        setActive(false);
    };

    const dateStyle = `
    .spin-button-none::-webkit-outer-spin-button,
    .spin-button-none::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .spin-button-none {
      -moz-appearance: textfield;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }
  `;

    return (
        <div className={`w-${width}`}>
            {label && (<div className="font-inter text-[15px] pb-1 leading-5 text-[#344054] w-fit">
                {label} {mandatory && <span className="text-[#D92D20]">*</span>}
            </div>
            )}
            <style>{dateStyle}</style>
            <div
                onClick={!disabled ? handleClick : () => { }}

                className={`flex-auto justify-start items-center max-w-[220px] h-[42px] ${!disabled && "cursor-pointer"

                    } gap-2 rounded-md ${!date && error ? "border-[#F04438]" : "border-inputBorderGray"
                    } border pl-4 pr-40 pt-[6px] ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
            >
                {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 1.33398V4.00065M5.33333 1.33398V4.00065M2 6.66732H14M3.33333 2.66732H12.6667C13.403 2.66732 14 3.26427 14 4.00065V13.334C14 14.0704 13.403 14.6673 12.6667 14.6673H3.33333C2.59695 14.6673 2 14.0704 2 13.334V4.00065C2 3.26427 2.59695 2.66732 3.33333 2.66732Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg> */}
                {/*
//                 {date === "" ? (
//                     <div className="whitespace-nowrap pt-[1px] text-textGray">{placeholder}</div>
//                 ) : (
//                     <input
//                         type="date"
//                         readOnly
//                         name="input1"
//                         value={date}
//                         onChange={handleChange}
//                         onClick={() => setActive(false)}
//                         className={`outline-none pointer-events-none border-none ${date === undefined || date === null || !date
//                             ? "text-textGray"
//                             : "text-textBlack"
//                             } ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
//                     />
//                 )}
*/}
                <input
                    type="text"
                    placeholder={placeholder}
                    readOnly
                    name="input1"
                    value={date}
                    onChange={handleChange}
                    onClick={() => setActive(false)}
                    className={`outline-none pointer-events-none border-none pt-[2px] py-[2px] ${date === undefined || date === null || !date
                        ? "text-textGray"
                        : "text-textBlack"
                        } ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
                />
            </div>
            {!date && error && (
                <div className=" absolute  text-[#F04438] font-normal text-[12px] text-left">
                    {error}
                </div>
            )}
            <input
                type="date"
                ref={dateRef}
                value={date}
                onChange={handleChange}
                className={`opacity-0 w-${width} pointer-events-none text-base relative -top-[26px]`}
                disabled={disabled}
            />
        </div>
    );
}