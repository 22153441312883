import Modal from "../../components/Modal";

export default function ModalType2({
  animation,
  title,
  description,
  isOpen,
  setIsOpen,
  undo,
}) {
  return (
    <>
      {isOpen &&
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isCloseButton={true}
        >
          <div className="flex flex-col items-center justify-center gap-1 -mt-1 pb-4">
            <img
              src={animation}
              alt=""
              className={` ${undo ? "h-20" : "h-32"}`}
            />
            <div className="flex flex-col items-center justify-center gap-1">
              <div className="text-lg font-semibold">{title}</div>
              <p className="text-lg font-semibold">{description}</p>
            </div>
            {undo && (
              <div className="flex justify-end w-full mt-1 pb-0">
                <button className="px-4 py-1 border-[1px] border-primaryBlue text-primaryBlue bg-blue-100 font-semibold rounded-lg">
                  UNDO
                </button>
              </div>
            )}
          </div>
        </Modal>
      }
    </>
  );
}
