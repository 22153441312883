import { useEffect, useRef } from "react";
import AddButton from "../../components/buttons/AddButton";
import FilterButton from "../../components/buttons/FilterButton";
import SearchWithToolTip from "../../components/inputs/SearchWithToolTip";

export default function ModuleLayout({
  title,
  description,
  buttonTitle,
  buttonwidth,
  toolTipText,
  isFilterOpen,
  setIsFilterOpen,
  filterChildren,
  children,
}) {
  // const [isAddButtonOpen, setIsAddButtonOpen] = useState(false);
  const filterRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line 
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between w-full">
        {title && (
          <div>
            <h3 className="pt-3 text-2xl font-semibold">{title}</h3>
            <h3 className="pt-1 text-textGray">{description}</h3>
            <div className="pt-5">
              {toolTipText && (
                <SearchWithToolTip
                  TooltipText={toolTipText}
                  TooltipWidth={"300px"}
                  SearchWidth={"300px"}
                />
              )}
            </div>
          </div>
        )}
        <div>
          {buttonTitle && (
            <AddButton
              buttonTitle={buttonTitle}
              buttonwidth={buttonwidth}
            // onClick={() => setIsAddButtonOpen(true)}
            />
          )}
          <div className="flex justify-end">
            <div>
              {filterChildren && (
                <FilterButton onClick={() => setIsFilterOpen(true)} />
              )}
              {isFilterOpen && <div>{filterChildren}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full">{children}</div>
    </div>
  );
}
